import ApiService from "@/core/services/ApiService";
import { FormData } from "@/core/types/gws-master/master/syarikah/FormData";
import { ListItem } from "@/core/types/gws-master/master/syarikah/ListItem";
import { SyarikahDetailWrapper } from "@/core/types/gws-master/master/syarikah/SyarikahWrapper";
import { ApiResponse, ErrorItem, Meta } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";

export const useSyarikahFormStore = defineStore({
  id: "syarikahFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
  },
  actions: {
    async insertData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          email: this.formData.email,
          password: this.formData.password,
          address: this.formData.address,
          pic_name: this.formData.pic_name,
          pic_email: this.formData.pic_email,
          pic_phone: this.formData.pic_phone,
          pic_id_number: this.formData.pic_id_number,
          pic_signature_file: this.formData.pic_signature_file,
          is_active: true,
          province_id: this.formData.province_id,
          city_id: this.formData.city_id,
          company_ids: this.formData.company_ids,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/syarikah`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          email: this.formData.email,
          address: this.formData.address,
          pic_name: this.formData.pic_name,
          pic_email: this.formData.pic_email,
          pic_phone: this.formData.pic_phone,
          pic_id_number: this.formData.pic_id_number,
          pic_signature_file: this.formData.pic_signature_file,
          is_active: this.formData.is_active,
          province_id: this.formData.province_id,
          city_id: this.formData.city_id,
          company_ids: this.formData.company_ids,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/syarikah/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async switchStatus(status: boolean) {
      this.stateIsLoading = true;
      try {
        const payload = {
          is_active: status,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/syarikah/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
          this.stateIsLoading = false;
        } else {
          this.stateError = true;
          this.stateIsLoading = false;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/syarikah/${id}`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async getSyarikahDetail(id: string) {
      try {
        const response: AxiosResponse<
          ApiResponse<SyarikahDetailWrapper<ListItem>>
        > = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `syarikah/${id}`
        );
        const syarikah = response.data.data.syarikah;
        const company_ids = [] as string[];
        syarikah.company.forEach((el) => {
          company_ids.push(el.id);
        });
        this.stateFormData = {
          name: syarikah.name,
          email: syarikah.user.email,
          password: "",
          address: syarikah.address,
          pic_name: syarikah.pic_name,
          pic_id_number: syarikah.pic_id_number,
          pic_signature_file: syarikah.pic_signature_file,
          pic_email: syarikah.pic_email,
          pic_phone: syarikah.pic_phone,
          is_active: syarikah.is_active,
          province_id: syarikah.province.id,
          city_id: syarikah.city.id,
          company_ids: company_ids,
        };
      } catch (error) {
        console.log(error);
      }
    },
    setFormData(data: FormData) {
      this.stateFormData = {
        name: data.name || "",
        email: data.email || "",
        password: data.password || "",
        address: data.address || "",
        pic_id_number: data.pic_id_number || "",
        pic_signature_file: data.pic_signature_file || "",
        pic_name: data.pic_name || "",
        pic_email: data.pic_email || "",
        pic_phone: data.pic_phone || "",
        is_active: data.is_active,
        province_id: data.province_id || "",
        city_id: data.city_id || "",
        company_ids: [],
      };
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
  },
});
